import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParallaxHeaderDirective } from 'src/app/directives/parallax-header.directive';



@NgModule({
  declarations: [ParallaxHeaderDirective],
  imports: [
    CommonModule
  ],
  exports: [
    ParallaxHeaderDirective
  ]
})
export class ParallaxModule { }
