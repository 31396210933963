import { Directive, ElementRef, Renderer2, HostListener, OnInit } from '@angular/core';
import { DomController } from '@ionic/angular';

@Directive({
  selector: '[parallaxHeader]'
})
export class ParallaxHeaderDirective implements OnInit {
  header: any;
  headerHeight: number;
  moveImage: number;
  scaleImage: number;

  constructor(private element: ElementRef, private renderer: Renderer2, private domCtrl: DomController) {}

  ngOnInit() {
    let content = this.element.nativeElement;
    this.header = content.getElementsByClassName('parallax-image')[0];
  }

  @HostListener('ionScroll', ['$event']) onContentScroll($event) {
    const scrollTop = $event.detail.scrollTop;
    if (!this.headerHeight) this.headerHeight = this.header.clientHeight;
    this.domCtrl.write(() => {
      if (scrollTop > 0) {
        this.moveImage = scrollTop / 2;
        this.scaleImage = 1;
      } else {
        this.moveImage = scrollTop / 1.4;
        this.scaleImage = -scrollTop / this.headerHeight + 1;
      }

      this.renderer.setStyle(
        this.header, 'webkitTransform', 
        'translate3d(0, ' + this.moveImage + 'px,0) scale(' + this.scaleImage + ',' + this.scaleImage + ')');
    });
  }
}